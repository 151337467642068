import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Apis from 'utils/Apis';
import { API_AXIOS } from 'http/axiosInterceptor';
const initialState = {
    dashboard: [],
    dashboardLoading: false,
    backgroundFetch: false
};

export const getDashboard = createAsyncThunk('dashboardList', async ({ startDate, endDate }) => {
    const { data } = await API_AXIOS.get(`${Apis.get_dashboardData}?from=${startDate}&to=${endDate}`);
    return data;
});

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: {
        [getDashboard.pending]: (state) => {
            state.dashboardLoading = true;
        },
        [getDashboard.fulfilled]: (state, { payload }) => {
            state.dashboardLoading = false;
            state.dashboard = payload;
        },
        [getDashboard.rejected]: (state) => {
            state.dashboardLoading = false;
        }
    }
});
export const dashboardSliceReducer = dashboardSlice.reducer;
