// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    openItem: []
};

// ==============================|| SLICE - MENU ||============================== //

const subMenu = createSlice({
    name: 'subMenu',
    initialState,
    reducers: {
        activeSubItem(state, action) {
            state.openItem = action.payload;
        }
    }
});

export default subMenu.reducer;

export const { activeSubItem, activeSubComponent } = subMenu.actions;
