import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Apis from 'utils/Apis';
import { API_AXIOS } from 'http/axiosInterceptor';
import { sortJson } from 'utils/Utils';
const initialState = {
    zones: [],
    zonesLoading: false
};

export const getZone = createAsyncThunk('zoneList', async (thunkAPI) => {
    const { data } = await API_AXIOS.get(Apis.getAllZones);
    return sortJson(data?.data, 'created_at');
});

export const zonesSlice = createSlice({
    name: 'zones',
    initialState,
    reducers: {},
    extraReducers: {
        [getZone.pending]: (state) => {
            state.zonesLoading = true;
        },
        [getZone.fulfilled]: (state, { payload }) => {
            state.zonesLoading = false;
            state.zones = payload;
            state.timeStamp = Date.now();
        },
        [getZone.rejected]: (state) => {
            state.zonesLoading = false;
        }
    }
});
export const zonesSliceReducer = zonesSlice.reducer;
