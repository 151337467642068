// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import subMenu from './subMenu';
import mode from './mode';
import authSlice from './authSlice';
import { devicesSliceReducer } from 'store/reducers/device';
import snackBarSlice from './SnackBar';
import { stationSliceReducer } from 'store/reducers/station';
import { userSliceReducer } from 'store/reducers/user';
import { bookingSliceReducer } from 'store/reducers/booking';
import { vehicleSliceReducer } from 'store/reducers/vehicles';
import { tariffSliceReducer } from 'store/reducers/tariff';
import { zonesSliceReducer } from 'store/reducers/zone';
import { authApi } from 'services/authService/authService';
import { dashboardSliceReducer } from 'store/reducers/dashboard';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    menu,
    subMenu,
    mode,
    devices: devicesSliceReducer,
    stations: stationSliceReducer,
    users: userSliceReducer,
    bookings: bookingSliceReducer,
    vehicles: vehicleSliceReducer,
    tariffs: tariffSliceReducer,
    zones: zonesSliceReducer,
    dashboard: dashboardSliceReducer,
    auth: authSlice,
    snackBar: snackBarSlice,
    [authApi.reducerPath]: authApi.reducer
});

export default reducers;
