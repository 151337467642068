import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_AXIOS } from 'http/axiosInterceptor';
import Apis from 'utils/Apis';
const initialState = {
    vehicles: [],
    vehiclesLoading: false
};

export const getVehicles = createAsyncThunk('vehilceList', async (thunkApi) => {
    const { data } = await API_AXIOS.get(Apis.getAllVehicles, { showLoadingIndicator: true });

    return data?.data;
});

export const vehicleSlice = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {},
    extraReducers: {
        [getVehicles.pending]: (state) => {
            state.vehiclesLoading = true;
        },
        [getVehicles.fulfilled]: (state, { payload }) => {
            state.vehiclesLoading = false;
            state.vehicles = payload;
            state.timeStamp = Date.now();
        },
        [getVehicles.rejected]: (state) => {
            state.vehiclesLoading = false;
        }
    }
});

export const vehicleSliceReducer = vehicleSlice.reducer;
