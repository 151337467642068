// let baseUrl = 'http://gomassive-stage-1001169519.ap-south-1.elb.amazonaws.com/api/';
let baseUrl = 'http://gomassive-stage-1001169519.ap-south-1.elb.amazonaws.com/api/v3/';
//let baseUrl = 'http://localhost:3000/api/';
//let baseUrl = 'http://localhost:3000/api/v3/';

//dev apis
// console.log('NODE_ENV', process.env.NODE_ENV);
// if (process.env.NODE_ENV == 'staging') {
//     baseUrl = 'http://65.0.53.17:3003/api';
// }
//console.log('eng', baseUrl);
//const baseUrl = 'http://65.0.53.17:3003/api/';
//let baseUrl = 'http://localhost:3000/api/';
//socket dev url
//export const socketUrl = 'ws://65.0.53.17:3002';
export const socketUrl = 'ws://65.0.53.17:3001';
const Apis = {
    baseUrl: baseUrl,
    login: baseUrl + 'admin/login',
    registration: baseUrl + 'admin/register',
    profile: 'admin/admin-profile',
    saveDevice: 'device',
    getAllDeviceList: 'device/get_all-devices',
    getAllStationList: 'stations/get-all-stations',
    getAllUserList: 'users/get_all_users',
    getAllBookings: 'bookings/get_bookings',
    getAllVehicles: 'vehicles',
    getAllTariffs: 'stations/get-all-tariff',
    getAllZones: 'stations/get-all-zones',
    getDeviceDetailsById: 'device/get-device-by-id/',
    getLogsByDeviceId: 'logs/',
    getStationDetailsById: 'stations',
    deleteDeviceDetailsById: 'device/delete_device/',
    deleteStationDetailsById: 'stations/delete_station/',
    deleteTariffDetailsById: 'stations/delete_tariff/',
    deleteZoneById: 'stations/delete_zone/',
    save_station: 'stations/save_station',
    saveTariff: 'stations/save_tariff',
    saveZones: 'stations/save_zones',
    saveDeviceControl: 'device/save_device_control_data',
    getDeviceControl: 'device/get_device_control_data/',
    booking: 'bookings',
    count: 'device/count',
    analytics: 'device/analytics',
    vehicles: 'vehicles',
    location: 'location',
    getConsumeWatt: 'bookings/getConsumeWatt',
    getConsumeWattById: 'bookings/getConsumeWattById',
    startStopCharging: 'device/start-stop-charging-mqtt',
    getAllAdminUser: 'admin/get-all-admin-user',
    updateAdminData: 'admin/update-admin-data',
    save_bulk_station: 'stations/save_bulk_station',
    save_bulk_device: 'device/save_bulk_device',
    getlistcompany: 'admin/get-list_company',
    get_all_company_list: 'admin/get_company_list',
    save_company: 'admin/save_company',
    delete_company: 'admin/delete_company/',
    delete_bulk_device_station: 'device/delete_bulk_device_station/',
    get_current_bookings: 'bookings/get_live_bookings',
    end_session: 'bookings/stopCharging',
    feedback: 'feedback',
    getPaymentList: 'phonepe/getPaymentList',
    refundAmount: 'payment/refundAmount',
    refundStatus: 'payment/refundStatus',
    getMonthWiseAmount: 'payment/getPaymentList',
    updateEvVehicle: 'ev-vehicle',
    getEvVehicles: 'ev-vehicle',
    deleteEvVehicle: 'ev-vehicle',
    deleteRequestedStation: 'request-stations',
    addEvVehicle: 'ev-vehicle',
    adminRegister: 'admin/register',
    meterValueByDeviceId: 'meter_values/',
    paytmPaymentHistory: 'wallet/history',
    getMonthWisePaytmWalletAmount: 'wallet/month-wise',
    device_session: 'device_session',
    adminRegister: 'admin/register',
    get_dashboardData: 'device/get_analytics_dashboardData',
    save_ocpp_device_data: 'device/save_ocpp_device_data',
    getDeviceConsumeWattById: 'device/getConsumeWattById',
    paytmPaymentHistory: 'wallet/history',
    getMonthWisePaytmWalletAmount: 'wallet/month-wise',
    device_session: 'device_session',
    updateStation: 'stations/update_station',
    deleteStationImage: 'stations/delete_station_image'
};
export default Apis;
