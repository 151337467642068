import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
//import MainLayout from 'layout/MainLayout';
import ErrorPage from './Error';
const StationVerificationDetails = Loadable(lazy(() => import('pages/StationVerification/StationVerificationDetails')));
// render - dashboard
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/dashboard')));
const DeviceList = Loadable(lazy(() => import('../pages/Devices/DeviceList')));
const DeviceDetails = Loadable(lazy(() => import('../pages/Devices/DeviceDetails')));
const ChargingSessions = Loadable(lazy(() => import('../pages/chargingSessions/chargingSessions')));
const StationManagement = Loadable(lazy(() => import('../pages/Stations/stationManagement')));
const Booking = Loadable(lazy(() => import('../pages/bookingComps/Booking')));
const CurrentBooking = Loadable(lazy(() => import('../pages/bookingComps/CurrentBooking')));
const StationDetails = Loadable(lazy(() => import('../pages/Stations/StationDetails')));
const GeoCluster = Loadable(lazy(() => import('../pages/ChargerCluster/GeoCluster')));
const BookingDetails = Loadable(lazy(() => import('../pages/bookingComps/Details')));
const Feedback = Loadable(lazy(() => import('../pages/Feedback')));
const FeedbackDetails = Loadable(lazy(() => import('../pages/Feedback/Details')));
const StationVerification = Loadable(lazy(() => import('../pages/StationVerification/StationVerification')));

//User Management
const OrganizationUsers = Loadable(lazy(() => import('../pages/UserManagementPage/OrganizationUsers')));
const AccessManagementUsers = Loadable(lazy(() => import('../pages/UserManagementPage/AccessUsersManagement')));

//Fleet Management
const FleetDashboard = Loadable(lazy(() => import('../pages/FleetManagementPage/index')));
const Geoference = Loadable(lazy(() => import('../pages/FleetManagementPage/Geoference')));
const VehicleDrivers = Loadable(lazy(() => import('../pages/FleetManagementPage/VehicleDrivers')));
const EvVehicles = Loadable(lazy(() => import('../pages/FleetManagementPage/evVehicles')));

//Tariff Management
const TariffPlans = Loadable(lazy(() => import('pages/TariffManagement/TariffPlans')));

//Fota Management
const FotaManagement = Loadable(lazy(() => import('pages/FotaManagement/index')));

//MapView
const MapView = Loadable(lazy(() => import('pages/Maps/MapView')));

//Zones & Load Balance
const LoadBalance = Loadable(lazy(() => import('pages/LoadBalance')));
const CompanyAccessManagement = Loadable(lazy(() => import('pages/companyManagement/CompanyAccessManagement')));
const NearbyDevices = Loadable(lazy(() => import('components/nearby_stations/NearbyDevices')));
const StateManagement = Loadable(lazy(() => import('pages/Stations/stationManagement')));
const ContainerPayment = Loadable(lazy(() => import('pages/payment/Container')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'charging_management',
            children: [
                {
                    path: 'dashboard',
                    element: <DashboardDefault />
                },
                {
                    path: 'device_list',
                    element: <DeviceList />
                },
                {
                    path: 'device_details/:id',
                    element: <DeviceDetails />
                },
                {
                    path: 'charging_sessions',
                    element: <ChargingSessions />
                },
                {
                    path: 'booking',
                    element: <Booking bookingType="all" />
                },
                {
                    path: 'anonymousbooking',
                    element: <Booking bookingType="anonymous" />
                },
                {
                    path: 'current_booking',
                    element: <CurrentBooking />
                },
                {
                    path: 'booking_details/:id',
                    element: <BookingDetails />
                },
                {
                    path: 'geo_cluster',
                    element: <GeoCluster />
                },
                {
                    path: 'feedback',
                    element: <Feedback />
                },
                {
                    path: 'feedback/:id',
                    element: <FeedbackDetails />
                }
            ]
        },
        {
            path: 'station_management',
            children: [
                {
                    path: 'station_list',
                    element: <StationManagement />
                },
                {
                    path: 'station_details/:id',
                    element: <StationDetails />
                },
                {
                    path: 'verify_stations',
                    element: <StationVerification />
                },
                {
                    path: 'station_verification_details/:id',
                    element: <StationVerificationDetails />
                }
            ]
        },
        {
            path: 'nearby_stations',
            element: <NearbyDevices />
        },
        {
            path: 'fleet_management',
            children: [
                { path: 'dashboard', element: <FleetDashboard /> },
                {
                    path: 'geoference',
                    element: <Geoference />
                },
                {
                    path: 'vehicle_drivers',
                    element: <VehicleDrivers />
                },
                {
                    path: 'ev_vehicles',
                    element: <EvVehicles />
                }
            ]
        },
        {
            path: 'user_management',
            children: [
                {
                    path: 'users',
                    element: <OrganizationUsers />
                }
            ]
        },
        {
            path: 'tariff_management',
            element: <TariffPlans />
        },
        {
            path: 'mapview',
            element: <MapView />
        },
        {
            path: 'fota_management',
            element: <FotaManagement />
        },
        {
            path: 'user-access-management',
            element: <AccessManagementUsers />
        },
        {
            path: 'stations',
            element: <StateManagement />
        },
        {
            path: 'company-access-management',
            element: <CompanyAccessManagement />
        },
        {
            path: 'customer_management',
            element: <OrganizationUsers />
        },
        {
            path: 'load_balancing',
            element: <LoadBalance />
        },
        {
            path: 'userPayment',
            element: <ContainerPayment />
        },
        {
            path: '*',
            children: [
                {
                    path: '*',
                    element: <ErrorPage />
                }
            ]
        }
    ]
};

export default MainRoutes;
