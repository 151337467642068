// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    openItem: ['dashboard'],
    openComponent: 'buttons',
    collaps: [],
    drawerOpen: false,
    userDetailDrawer: false,
    componentDrawerOpen: true
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.openItem = action.payload.openItem;
        },
        collapsItem(state, action) {
            state.collaps = action.payload.collaps;
        },
        activeComponent(state, action) {
            state.openComponent = action.payload.openComponent;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload.drawerOpen;
        },
        userDrawerToggle(state, action) {
            state.userDetailDrawer = action.payload.open;
        },
        openComponentDrawer(state, action) {
            state.componentDrawerOpen = action.payload.componentDrawerOpen;
        }
    }
});

export default menu.reducer;

export const { activeItem, activeComponent, collapsItem, openDrawer, userDrawerToggle, openComponentDrawer } = menu.actions;
