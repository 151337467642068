import axios from 'axios';
import { onLoadingIndicator } from './loadingEvent';
import Apis from 'utils/Apis';
const requestInterceptor = (config) => {
    return new Promise((resolve, reject) => {
        if ('showLoadingIndicator' in config) {
            const event = onLoadingIndicator(true);
            document.dispatchEvent(event);
        }
        const token = localStorage.getItem('userToken');
        if (token) {
            config.headers.authorization = `Bearer ${token}`;
        }
        //config.params = { ...config.params, my_variable: 'value' };
        setTimeout(() => resolve(config), 1000);
    });
};

const responseInterceptor = (response) => {
    console.log('response', response);
    if ('showLoadingIndicator' in response.config) {
        const event = onLoadingIndicator(false);
        document.dispatchEvent(event);
    }
    return response;
};

const API_AXIOS = axios.create({
    baseURL: Apis.baseUrl,
    headers: {
        'Content-Type': 'application/json'
    }
});
const API_AXIOS_MULTIPART = axios.create({
    baseURL: Apis.baseUrl,
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`
    }
});

const API_AXIOS_AUTH = axios.create({
    baseURL: Apis.baseUrl,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`
    }
});

API_AXIOS.interceptors.request.use(requestInterceptor);
API_AXIOS.interceptors.response.use(responseInterceptor);
API_AXIOS_MULTIPART.interceptors.request.use(requestInterceptor);
API_AXIOS_MULTIPART.interceptors.response.use(responseInterceptor);
API_AXIOS_AUTH.interceptors.request.use(requestInterceptor);
API_AXIOS_AUTH.interceptors.response.use(responseInterceptor);
export { API_AXIOS, API_AXIOS_MULTIPART, API_AXIOS_AUTH };
