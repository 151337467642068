// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    openSnackBar: false,
    snackbarType: 'success',
    msg: ''
};

// ==============================|| SLICE - MENU ||============================== //

const snackBarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        openSnackbarHandler(state, action) {
            return action.payload;
        },
        closeSnackbarHandler(state, action) {
            return action.payload;
        }
    }
});

export default snackBarSlice.reducer;

export const { openSnackbarHandler, closeSnackbarHandler } = snackBarSlice.actions;
