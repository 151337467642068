import { createSlice } from '@reduxjs/toolkit';

const modeSlice = createSlice({
    name: 'mode',
    initialState: localStorage.getItem('mode') || 'light',
    reducers: {
        toggleMode: (state) => {
            localStorage.setItem('mode', state === 'dark' ? 'light' : 'dark');
            return state === 'light' ? 'dark' : 'light';
        }
    }
});

export default modeSlice.reducer;

export const { toggleMode } = modeSlice.actions;
