import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Apis from 'utils/Apis';
import { API_AXIOS } from 'http/axiosInterceptor';
import { sortJson } from 'utils/Utils';
const initialState = {
    bookings: [],
    bookingLoading: false
};

export const getBookings = createAsyncThunk('bookingList', async (thunkApi) => {
    const { data } = await API_AXIOS.get(Apis.getAllBookings, { showLoadingIndicator: false });

    return sortJson([...data.data.recent_bookings, ...data.data.user_active_sessions], 'booking_start_dateTime');
});

const bookingSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {},
    extraReducers: {
        [getBookings.pending]: (state) => {
            state.bookingLoading = true;
        },
        [getBookings.fulfilled]: (state, { payload }) => {
            state.bookingLoading = false;
            state.bookings = payload;
        },
        [getBookings.rejected]: (state) => {
            state.bookingLoading = false;
        }
    }
});

export const bookingSliceReducer = bookingSlice.reducer;
