import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LandingPage = Loadable(lazy(() => import('pages/LandingPage')));

// project import

// ==============================|| AUTH ROUTING ||============================== //

const LandingRoute = {
    path: '/landing_page',
    element: <LandingPage />
};

export default LandingRoute;
