import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Apis from 'utils/Apis';
import { API_AXIOS } from 'http/axiosInterceptor';
import { sortJson } from 'utils/Utils';
const initialState = {
    stations: [],
    stationsLoading: false
};

export const getStation = createAsyncThunk('stationList', async (thunkAPI) => {
    const { data } = await API_AXIOS.get(Apis.getAllStationList, { showLoadingIndicator: false });
    return sortJson(data, 'created_at');
});

export const stationSlice = createSlice({
    name: 'stations',
    initialState,
    reducers: {},
    extraReducers: {
        [getStation.pending]: (state) => {
            state.stationsLoading = true;
        },
        [getStation.fulfilled]: (state, { payload }) => {
            state.stationsLoading = false;
            state.stations = payload;
            state.timeStamp = Date.now();
        },
        [getStation.rejected]: (state) => {
            state.stationsLoading = false;
        }
    }
});
export const stationSliceReducer = stationSlice.reducer;
