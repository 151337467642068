// third-party
import { configureStore } from '@reduxjs/toolkit';

// project import
import reducers from './reducers';
import { authApi } from '../services/authService/authService';
// We'll use redux-logger just as an example of adding another middleware
import logger from 'redux-logger';

// And use redux-batched-subscribe as an example of adding enhancers
import { batchedSubscribe } from 'redux-batched-subscribe';
// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const debounceNotify = _.debounce((notify) => notify());
const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), logger, authApi.middleware],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [batchedSubscribe(debounceNotify)]
});

const { dispatch } = store;

export { store, dispatch };
