import * as moment from 'moment-timezone';

moment.locale('fr');

moment.tz.setDefault('Asia/Kolkata');
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
require('dayjs/locale/de');
dayjs.locale('fr');

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const sortJson = (array, key) => {
    if (array) {
        return array.sort((a, b) => {
            if (a[key] < b[key]) {
                return 1;
            }
            if (a[key] > b[key]) {
                return -1;
            }
            return 0;
        });
    }
    return [];
};

export const humanReableDate = (date) => {
    return dayjs.utc(date).locale('fr').tz('Asia/Kolkata').format('MMM D, YYYY h:mm A');
    //return dayjs.utc(date).format('MMM D, YYYY h:mm A');
};
export const convertDateToMilisecont = (date) => {
    return dayjs.utc(date).locale('fr').tz('Asia/Kolkata').valueOf();
    //return dayjs.utc(date).format('MMM D, YYYY h:mm A');
};
export const getCurrentDateTime = () => {
    const dt = new Date();
    var localeDate = dt.toLocaleString('fr-FR', { timeZone: 'Asia/Kolkata' });
    return moment.utc(localeDate, 'DD-MM-YYYY').tz('Asia/Kolkata').valueOf();
};

export function convertStartDate(startDate) {
    return convertUTCToTimezone(
        moment(`${convertDate(convertUTCToTimezone(moment(moment(startDate))))} 00:00`, 'DD-MM-YYYY HH:mm').local(true)
    );
}
export function convertEndDate(endDate) {
    return convertUTCToTimezone(moment(`${convertDate(convertUTCToTimezone(moment(endDate)))} 24:00`, 'DD-MM-YYYY HH:mm').local(true));
}
export function convertDate(date) {
    const dt = new Date(date);
    var localeDate = dt.toLocaleString('fr-FR', { timeZone: 'Asia/Kolkata' });
    return moment.utc(localeDate, 'DD-MM-YYYY').tz('Asia/Kolkata').format('DD-MM-YYYY');
}
function convertUTCToTimezone(utcDt) {
    //console.log("data",moment.utc(utcDt).tz('Asia/Kolkata').valueOf(),type)
    return moment.utc(utcDt).tz('Asia/Kolkata').valueOf();
}

export const subtractDay = (val) => {
    return moment.utc(moment().subtract(val, 'day')).tz('Asia/Kolkata').valueOf();
};
export const getCurrentDateTimeMoment = () => {
    return moment.utc(moment()).tz('Asia/Kolkata').valueOf();
};
export const getstartMonthYear = (val) => {
    return moment.utc(moment().startOf(val)).tz('Asia/Kolkata').valueOf();
};
export function getUniqueId() {
    return new Date().getTime() + Math.trunc(365 * Math.random());
}
