import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Apis from 'utils/Apis';
import { API_AXIOS } from 'http/axiosInterceptor';
const initialState = [];

export const getUsers = createAsyncThunk('userList', async (thunkApi) => {
    const { data } = await API_AXIOS.get(Apis.getAllUserList, { showLoadingIndicator: true });
    console.log(data);
    return data.data;
});

const userSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [getUsers.fulfilled]: (state, { payload }) => {
            return payload;
        },
        [getUsers.rejected]: (state) => {
            return [];
        }
    }
});

export const userSliceReducer = userSlice.reducer;
