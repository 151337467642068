import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Apis from 'utils/Apis';
export const userLogin = createAsyncThunk('user/login', async ({ email, password }, { rejectWithValue }) => {
    try {
        // configure header's Content-Type as JSON
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const { data } = await axios.post(`${Apis.login}`, { email, password }, config);
        if (data?.message == 'success') {
            // store user's token in local storage
            localStorage.setItem('userToken', data.data.token);
            localStorage.setItem('activeMenu', 'dashboard');
            return data.data;
        }
        if (data?.message === 'failed' && data.error) {
            return rejectWithValue(data.error);
        } else if (data?.message === 'notActive' && data.error) {
            return rejectWithValue(data.error);
        } else {
            return rejectWithValue(data.error);
        }
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const registerUser = createAsyncThunk('user/register', async ({ name, email, password, orgsName, orgsId }, { rejectWithValue }) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const { data } = await axios.post(`${Apis.registration}`, { name, email, password, orgsName, orgsId }, config);
        if (!data._id) {
            return rejectWithValue(data.error);
        }
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
