import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Apis from 'utils/Apis';
import { API_AXIOS } from 'http/axiosInterceptor';
import { sortJson } from 'utils/Utils';
const initialState = {
    devices: [],
    devicesLoading: false
};

export const getDevice = createAsyncThunk('deviceList', async (thunkAPI) => {
    const { data } = await API_AXIOS.get(Apis.getAllDeviceList, { showLoadingIndicator: false });

    return data;
});

export const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {},
    extraReducers: {
        [getDevice.pending]: (state) => {
            state.devicesLoading = true;
        },
        [getDevice.fulfilled]: (state, { payload }) => {
            state.devicesLoading = false;
            state.devices = payload;
            state.timeStamp = Date.now();
        },
        [getDevice.rejected]: (state) => {
            state.devicesLoading = false;
        }
    }
});
export const devicesSliceReducer = devicesSlice.reducer;
