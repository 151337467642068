import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_AXIOS } from 'http/axiosInterceptor';
import Apis from 'utils/Apis';
import { sortJson } from 'utils/Utils';
const initialState = {
    tariffs: [],
    tariffLoading: false
};

export const getTariff = createAsyncThunk('tariffList', async (thunkAPI) => {
    const { data } = await API_AXIOS.get(Apis.getAllTariffs, { showLoadingIndicator: true });
    return data.data;
});

export const tariffSlice = createSlice({
    name: 'tariffs',
    initialState,
    reducers: {},
    extraReducers: {
        [getTariff.pending]: (state) => {
            state.tariffLoading = true;
        },
        [getTariff.fulfilled]: (state, { payload }) => {
            state.tariffLoading = false;
            state.tariffs = payload;
        },
        [getTariff.rejected]: (state) => {
            state.devicesLoading = false;
        }
    }
});
export const tariffSliceReducer = tariffSlice.reducer;
